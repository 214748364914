<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-toolbar-title class="headline font-weight-bold">
                <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
                <a class="text-decoration--none">Account settings</a>
            </v-toolbar-title>
        </v-app-bar>

        <v-container fill-height>
            <v-row class="justify-center align-center">
                <v-col cols="12" sm="6">
                    <div
                        class="display-1 font-weight-bold my-1 text-capitalize"
                    >
                        Change password
                    </div>
                    <v-form ref="form" v-if="form">
                        <label class="text-field-label">Old password</label>
                        <v-text-field
                            flat
                            type="password"
                            outlined
                            solo
                            required
                            class="mt-2"
                            v-model="form.old_password"
                            :error-messages="form.$getError('old_password')"
                        ></v-text-field>

                        <label class="text-field-label">New password</label>
                        <v-text-field
                            flat
                            outlined
                            solo
                            required
                            class="mt-2"
                            @click:append="() => (showPassword = !showPassword)"
                            :append-icon="
                                showPassword ? icons.eyeOn : icons.eyeOff
                            "
                            :type="showPassword ? 'text' : 'password'"
                            v-model="form.new_password"
                            :error-messages="form.$getError('new_password')"
                        ></v-text-field>

                        <div class="mt-4">
                            <v-btn
                                color="primary"
                                depressed
                                class="mr-4 px-6"
                                height="40px"
                                :loading="form.$busy"
                                @click="submitForm"
                                >Change</v-btn
                            >
                        </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
            {{ snackbar.message }}
            <v-btn text @click="snackbar.show = false">OK</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Form from '@/utils/form'
import { mdiChevronLeft, mdiEye, mdiEyeOff } from '@mdi/js'
export default {
    components: {
        AppBarNavIcon
    },
    data() {
        return {
            page: 'create',
            form: new Form({}),
            showPassword: false,
            icons: {
                arrowLeft: mdiChevronLeft,
                eyeOn: mdiEye,
                eyeOff: mdiEyeOff
            },
            deleteDialog: false,
            snackbar: {
                show: false,
                message: null,
                color: ''
            }
        }
    },
    methods: {
        submitForm() {
            this.$api
                .post('/admin/auth/change-password', this.form)
                .then(res => {
                    this.showSnackbar(
                        'Password successfully changed.',
                        'success'
                    )
                    this.form.$setErrors({})
                })
                .catch(err => {
                    this.form.$setErrors(err.response.data.errors)
                })
        },

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.show = true
            this.snackbar.color = color
        },

        deleteRole() {}
    }
}
</script>
