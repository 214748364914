<template>
    <change-password />
</template>

<script>
import ChangePassword from './components/changePassword'
export default {
    components: {
        ChangePassword
    }
}
</script>
